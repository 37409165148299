<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.CALCULATE_COST}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row v-for="(mainCost, index) in itemsMainCost" :key="index">
          <v-col cols="12" sm="8">
            <h2 class="caption">{{typeCost(index, mainCost.costName)}}</h2>
            <div class="d-flex">
              <v-text-field
                outlined
                dense
                disabled
                v-model="mainCost.quantity"
                hide-details
              ></v-text-field>
              <v-select
                v-if="mainCost.costName && mainCost.costName !== 'MULTIPICK' && mainCost.costName !== 'MULTIDROP'"
                v-model="mainCost.costName"
                :items="itemsTypeCost"
                hide-details
                dense
                outlined
                disabled
              >
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <h2 v-if="typeCost(index, mainCost.costName)" class="caption">{{$_strings.order.AMOUNT_OF_COSTS}}</h2>
            <v-text-field
              outlined
              dense
              :value="formatAsCurrency(mainCost.totalPrice)"
              hide-details
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="auto">
            <p>Pengiriman ke - {{shipmentOrder}}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              hide-default-footer
              :headers="headers"
              calculate-widths
              :items="cost"
              :items-per-page="1000"
              key="id"
            >
              <template v-slot:[`item.costName`]={item,index}>
                <common-auto-complete-items
                  type="additionalCost"
                  searchName="name"
                  item-value="id"
                  item-text="reason"
                  v-model="item.id"
                  dense
                  outlined
                  hide-details
                  class="body-2"
                  clearable
                  :filter="costFilterItems"
                  :items="itemsCost"
                  @updateItems="updateCostListItems"
                  @change="changeCostName(index, item)"
                />
              </template>
              <template v-slot:[`item.totalPrice`]={item,index}>
                <v-text-field
                  @input="($event) => inputChanged($event, index)"
                  v-mask="{'alias': 'idr-currency', rightAlign: false}"
                  :value="formatAsCurrency(item.totalPrice)"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:[`item.notes`]={item,index}>
                <v-text-field
                  @input="($event) => inputNote($event, index)"
                  :value="item.notes"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </template>
            </v-data-table>
            <div class="mt-2">
              <v-btn
                @click="addRowTable"
                class="pa-0"
                text>
                <v-icon color="primary">mdi-plus-circle</v-icon>
                <span class="primary--text">Tambah Baris</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row class="grey lighten-5">
          <v-col class="pb-0" cols="12" sm="8">
            <p class="title mb-0">{{$_strings.order.TOTAL_COST}}</p>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :value="formatAsCurrency(totalPrice)"
              disabled
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-2"></v-divider>
        <v-row>
          <v-col class="pb-0" cols="12" sm="8">
            <p class="title mb-0">{{$_strings.order.TOTAL_BILL}}</p>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :value="formatAsCurrency(totalPrice)"
              disabled
              outlined
              dense
              hide-details
            ></v-text-field>
            <p class="caption text-end">(Belum termasuk biaya Pengiriman)</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
          :disabled="isLoading || !itemsMainCost.length"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { convertTextTypeCost } from '@/helper/commonHelpers';

export default {
  data() {
    return {
      isLoading: false,
      isLoadingGetCostItems: false,
      dialog: false,
      headers: [
        {
          text: this.$_strings.order.ADDITIONAL_COST,
          value: 'costName',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.AMOUNT_OF_COSTS,
          value: 'totalPrice',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESCRIPTION,
          value: 'notes',
          class: 'white--text primary',
          sortable: false,
        },
      ],
      itemsMainCost: [],
      itemsTypeCost: [],
      itemsCost: [],
      orderSelected: {},
      cost: [],
      costIdSelected: [],
      totalPrice: 0,
      shipmentOrder: 1,
      costFilterItems: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.itemsMainCost = [];
        this.cost = [];
        this.costIdSelected = [];
        this.totalPrice = 0;
        this.itemsCost = [];
        this.costFilterItems = {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        };
        return;
      }
      this.fetchData();
    },
  },
  methods: {
    convertTextTypeCost,
    updateCostListItems(items) {
      this.itemsCost = [...this.itemsCost, ...items];
    },
    typeCost(index, type) {
      if (type.toLowerCase() === 'multipick' || type.toLowerCase() === 'multidrop') return `${type} (Rp)`;
      if (index > 0) return null;
      return this.$_strings.order.MAIN_COST;
    },
    async getMainCost(shipmentsCargoId) {
      try {
        this.isLoading = true;
        const resultMainCost = await this.$_services.order.getMainCost(shipmentsCargoId);
        if (resultMainCost.data.mainCost) {
          this.itemsMainCost = resultMainCost.data.mainCost;
          this.itemsTypeCost = resultMainCost.data.mainCost.map((cost) => ({
            value: cost.costName,
            text: this.convertTextTypeCost(cost.costName),
          }));
          this.setTotalPrice();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getCostItems(shipmentsCargoId) {
      try {
        this.isLoadingGetCostItems = true;
        const resultCostItems = await this.$_services.order.getUnitCost(shipmentsCargoId);
        if (resultCostItems.data) {
          const result = [];
          resultCostItems.data.forEach((costItem) => {
            if (costItem.cost) {
              costItem.cost.forEach((cost) => {
                result.push(cost);
              });
            }
          });
          if (result.length) {
            this.cost = result;
          }

          // SET ITEM COST
          result.forEach((itemcost) => {
            if (!(this.itemsCost.find((item) => item.id === itemcost.id))) {
              this.itemsCost.push({
                id: itemcost.id,
                reason: itemcost.costName,

              });
            }
          });

          this.setTotalPrice();
        }
      } finally {
        this.isLoadingGetCostItems = false;
      }
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    inputChanged(event, index) {
      if (+event.charAt(0) === 0) {
        event = event.substr(1, event.length);
      }
      const newPrice = (event.replace(/[A-Za-z]/g, 0).replace(/[^0-9]/g, '')) || 0;
      this.cost[index].totalPrice = +newPrice;
      this.setTotalPrice();
    },
    inputNote(event, index) {
      this.cost[index].notes = event;
    },
    setTotalPrice() {
      const totalMainCost = this.itemsMainCost.reduce((currentTotal, item) => +item.totalPrice + currentTotal, 0);
      if (this.cost.length === 0) {
        this.totalPrice = totalMainCost;
        return;
      }
      this.totalPrice = this.cost.reduce((total, cost) => +cost.totalPrice + total, totalMainCost);
    },
    fetchData() {
      const { id } = this.orderSelected;
      this.getMainCost(id);
      this.getCostItems(id);
    },
    addRowTable() {
      this.cost.push({
        id: this.cost.length,
        costName: '',
        isTaxed: '',
        totalPrice: 0,
        quantity: '',
        costType: '',
        notes: '',
        oracleCostInternalCode: 0,
        oracleCostInternalDescription: '',
        oracleCostExternalCode: 0,
        oracleCostExternalDescription: '',
      });
    },
    changeCostName(index, item) {
      if (item.id) {
        const getCost = this.itemsCost.find((cost) => cost.id === item.id);
        this.cost[index].costName = getCost.reason;
        this.cost[index].isTaxed = getCost.needConfirm;
        this.cost[index].quantity = 1;
        this.cost[index].costType = getCost.type;
        this.cost[index].oracleCostInternalCode = getCost.oracleCostInternalCode;
        this.cost[index].oracleCostInternalDescription = getCost.oracleCostInternalDescription;
        this.cost[index].oracleCostExternalCode = getCost.oracleCostExternalCode;
        this.cost[index].oracleCostExternalDescription = getCost.oracleCostExternalDescription;
      }
    },
    async save() {
      const { id } = this.orderSelected;
      try {
        this.isLoading = true;
        const data = [];
        let isValid = true;
        if (this.cost.length) {
          this.cost.forEach((cost) => {
            if (!(cost.costName) || !(cost.totalPrice)) {
              isValid = false;
            }
            data.push(cost);
          });
        }
        if (!isValid) {
          this.$dialog.notify.error('Mohon periksa field yang belum diisi');
          return;
        }
        const form = { cost: data };
        await this.$_services.order.saveCost(id, form);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
        this.$emit('fetchShipmentDetail');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
